@tailwind base;
@tailwind components;
@tailwind utilities;

.arrow {
    width: 60px;
    height: 60px;
    stroke-width: 0.5px;
    color: white;
}

/* resume button */

  .resBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 130px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    color: #fff;
    font-size: 24px;
    text-transform: uppercase;
    text-decoration: none;
    font-family: sans-serif;
    box-sizing: border-box;
    background: linear-gradient(90deg, rgb(230, 188, 237), rgb(221, 56, 250), rgb(119, 0, 255), violet, purple, blue, rgb(153, 13, 235));
    background-size: 400%;
    border-radius: 30px;
    z-index: 1;
  }

  @keyframes animate {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }
  .resBtn:before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(45deg, #ffa0fc, #c385f6, #7c3697);
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
    transition: 0.5%;
  }
  .resBtn:hover:before {
    filter: blur(20px);
    opacity: 1;
    animation: animate 8s linear infinite;
  }
  .carousel-img {
    max-width: 100%;
    max-height: 300px;
    object-fit: cover;
  }
  