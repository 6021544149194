@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding-top: 60px; /* Adjust this value based on the height of your navbar */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel-img {
  max-width: 100%;
  max-height: 300px;
  object-fit: cover;
}


/* Ensure the main container accounts for the navbar height */
.min-h-screen {
  min-height: calc(100vh - 60px); /* Adjust this value based on the height of your navbar */
}

/* Navbar styles, if not already defined */
.navbar {
  @apply fixed top-0 w-full z-50;
}

/* Responsive layout for glass card */
.glass-card {
  @apply flex flex-col md:flex-row items-center;
}

.glass-card img {
  @apply w-full md:w-1/3 rounded-lg;
}

.glass-card div {
  @apply mt-4 md:mt-0 md:ml-5;
}


/* Custom styles for neon yellow button */
.btn-primary {
    @apply bg-violet-900 text-white; /* Neon yellow with black text */
  }
  
  .btn-primary:hover {
    @apply bg-violet-200; /* Lighter yellow on hover */
  }